import '~/assets/styles/index.scss';

import React from 'react';
import { GeoLocationProvider } from '~/app/context';

export const wrapRootElement = ({ element }) => {
  return <GeoLocationProvider>{element}</GeoLocationProvider>;
};

setTimeout(() => {
  (window.__ctm_loaded || []).push(function () {
    __ctm.form.capture(
      'app.calltrackingmetrics.com', // the capture host
      'FRT472ABB2C5B9B141AE4D266433DD45C5F26A5B5E050DA6762FC5168FCB53908A2', // this FormReactor
      document.getElementById('ctm-form'), // ID of the web form you want to attach your FormReactor
      '6196484818', // the tracking number used for associating visitor data and caller ID consumers will see
      {
        country_code: '1',
        name: 'your_first_name', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
        phone: 'phone', // ID of the lead phone number field
        email: 'your_email',
      }
    );
  });
}, 1500);
